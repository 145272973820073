import React from 'react'
import { extract } from '../../../utils/graphql'
import { localizePath } from '../../../../config/i18n'
import { graphql } from 'gatsby'

import WysiwygWTout from '../../../components/Templates/WysiwygWTout'
import NoTranslation from '../../../components/Templates/NoTranslation'

// TODO: add this to global
import Twitter from '../../../components/Shapes/Twitter'
import LinkedIn from '../../../components/Shapes/LinkedIn'
import Telegram from '../../../components/Shapes/Telegram'
import Facebook from '../../../components/Shapes/Facebook'
import Medium from '../../../components/Shapes/Medium'
import { withPreview } from 'gatsby-source-prismic'

const DevCommunityPage = ({
  data: { prismicDeveloperResources },
  pageContext,
}) => {
  const page = prismicDeveloperResources
  if (!page) return <NoTranslation></NoTranslation>
  const hydratedData = {
    lang: pageContext.locale,
    seo: extract.seo(page),
    mantelData: extract.mantel(page, {
      parent: {
        path: localizePath(pageContext.locale, '/work-with-us'),
        text: 'Work With Us',
      },
    }),
    bg: 3,
    toutData: {
      ...extract.tout(page),
      follow: {
        heading: 'Follow Us',
        icons: [
          {
            shape: Twitter,
            url: 'https://twitter.com/Algorand',
          },
          {
            shape: LinkedIn,
            url: 'https://www.linkedin.com/company/algorand/',
          },
          {
            shape: Telegram,
            url: 'https://t.me/algorand',
          },
          {
            shape: Facebook,
            url: 'https://www.facebook.com/algorand/',
          },
          {
            shape: Medium,
            url: 'https://medium.com/algorand',
          },
        ],
      },
    },
    content: page.data.content.html,
    resourceTiles: page.data.resource_tiles.map((tile) => ({
      title: tile.resource_tile_title.text,
      copy: tile.resource_title_copy.text,
      color: tile.resource_tile_color,
      icon: tile.resource_tile_icon,
      link: tile.resource_tile_link.url,
    })),
  }
  return <WysiwygWTout {...hydratedData}></WysiwygWTout>
}

export default withPreview(DevCommunityPage)

export const pageQuery = graphql`
  query DevelpoerResourcesByLocale($locale: String!) {
    prismicDeveloperResources(lang: { eq: $locale }) {
      data {
        title {
          text
        }
        seo_keywords {
          keyword {
            text
          }
        }
        seo_description {
          text
        }
        mantel_title {
          text
        }
        tout_title {
          text
        }
        tout_image {
          url
          alt
        }
        tout_content {
          html
        }
        tout_button_text {
          text
        }
        tout_button_url {
          url
          link_type
        }
        content {
          html
        }
        resource_tiles {
          resource_tile_title {
            text
          }
          resource_title_copy {
            text
          }
          resource_tile_color
          resource_tile_icon
          resource_tile_link {
            url
          }
        }
      }
    }
  }
`
